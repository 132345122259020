import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useObservable } from "react-use";

import { getSetting$ } from "../../../services/preferences.service";
import LightEmblem from "../../../static/media/letter-emblem-light.png";
import DarkEmblem from "../../../static/media/letter-emblem-dark.png";
import "./about-me.scss";

const skillsTable = {
  backend: [],
  frontend: [],
  softwareEngineering: [],
  cloud: [],
  devOps: [],
};

function AboutMe() {
  const theme = useObservable(getSetting$("theme"), "light");
  function getExperienceTime(startDate: Date, endDate: Date) {
    // Parse the dates if they are strings
    const d1 =
      typeof startDate === "string"
        ? new Date(startDate).getTime()
        : startDate.getTime();
    const d2 =
      typeof endDate === "string"
        ? new Date(endDate).getTime()
        : endDate.getTime();

    // Calculate the difference in milliseconds
    const differenceInMilliseconds = Math.abs(d1 - d2);

    // Convert the difference to years
    const differenceInYears =
      differenceInMilliseconds / (1000 * 60 * 60 * 24 * 365.25);

    return Math.floor(differenceInYears);
  }
  return (
    <section id="about-me">
      <Container className="about-me">
        <Row className="about-me-content">
          <Col lg={6}></Col>
          <Col lg={6}>
            <h2>About Me</h2>
            <p>
              I'm a seasoned Senior Software Engineer with over 5 years of
              experience, specializing in full-stack development. My expertise
              lies in crafting scalable backend solutions using Node.js, PHP,
              and Python, while also excelling in frontend technologies like
              HTML, CSS, Bootstrap, JavaScript, and various frameworks. I'm
              proficient in integrating third-party APIs and skilled in project
              management tools and cloud technologies. With hands-on experience
              in scraper development and a track record of successful project
              leadership, I bring a strong blend of technical skills and
              effective communication to the table. Let's work together to bring
              your vision to life!
            </p>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default AboutMe;
