import React, { useEffect } from "react";
import { useObservable } from "react-use";

import { getSetting$ } from "./services/preferences.service"; // Adjust the import as per your folder structure
import HeroHeader from "./shared/components/hero-header/hero-header";
import AboutMe from "./shared/components/about-me/about-me";

function App() {
  const theme = useObservable(getSetting$("theme"), "light");

  // useEffect hook to update the body's data-bs-theme attribute whenever the theme changes
  useEffect(() => {
    document.body.setAttribute("data-bs-theme", theme);
  }, [theme]);
  return (
    <>
      <HeroHeader />
      <AboutMe />
    </>
  );
}

export default App;
