import React from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { DarkModeSwitch } from "react-toggle-dark-mode";
import { useObservable } from "react-use";

import {
  getSetting$,
  updateSetting,
} from "../../../services/preferences.service";
import "./hero-header.scss";

const socialLinks = [
  {
    icon: "linkedin",
    url: "https://linkedin.com/in/waleed-ahmed07",
  },
  {
    icon: "github",
    url: "https://github.com/waleedahmed0710",
  },
];

function HeroHeader() {
  const theme = useObservable(getSetting$("theme"), "light");
  return (
    <section id="home" className="hero">
      <Navbar expand="lg" className="bg-body-tertiary">
        <Container>
          <Navbar.Brand href="#home">
            <h4>Waleed.Ahmed</h4>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />

          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <Nav.Link href="#home">Home</Nav.Link>
              <Nav.Link href="#about">About Me</Nav.Link>
              <Nav.Link href="#experience">Professional Experience</Nav.Link>
              <Nav.Link href="#projects">Projects</Nav.Link>
              <Nav.Link href="#blog">Blog & Socials</Nav.Link>
              <Nav.Link href="#contact">Contact</Nav.Link>
              <Nav.Link>
                <DarkModeSwitch
                  style={{ marginBottom: "5px" }}
                  checked={theme === "dark" ? true : false}
                  onChange={() =>
                    updateSetting("theme", theme === "light" ? "dark" : "light")
                  }
                  size={20}
                />
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Container>
        <div className="hero-content">
          <div className="hero-text">
            <h1>Full Stack Developer & Software Engineer</h1>
            <p>
              Hi, I'm Waleed Ahmed. A seasoned Software Engineer & Full Stack
              Developer based in Lahore, Pakistan
            </p>
            <span className="social-links">
              <ul>
                {socialLinks.map((link, index) => (
                  <li key={index}>
                    <a href={link.url} target="_blank" rel="noreferrer">
                      <img
                        src={"https://skillicons.dev/icons?i=" + link.icon}
                        alt={link.icon}
                      />
                    </a>
                  </li>
                ))}
              </ul>
            </span>
          </div>
          <div className="hero-img"></div>
        </div>
      </Container>
    </section>
  );
}

export default HeroHeader;
