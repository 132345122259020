import { BehaviorSubject } from 'rxjs';
import { tap, map } from 'rxjs/operators';

// Initial State
const initialState = {
  theme: 'light',
  cookies: true,
};

// Try to get state from localStorage or fall back to initialState
const savedStateJSON = localStorage.getItem('settings');
const savedState = savedStateJSON ? JSON.parse(savedStateJSON) : initialState;


// BehaviorSubject to hold the state
const settingsSubject = new BehaviorSubject(savedState);

// Function to update a specific setting
const updateSetting = (key: string, value: any) => {
  settingsSubject.next({
    ...settingsSubject.value,
    [key]: value,
  });
};

// Observable to expose the state
const settings$ = settingsSubject.asObservable().pipe(
  tap((state) => {
    // Save the state to localStorage
    localStorage.setItem('settings', JSON.stringify(state));
  })
);

// Function to get a specific setting
const getSetting$ = (key: string) => settings$.pipe(map((settings: any) => settings[key]));

export { updateSetting, settings$, getSetting$ };